
import { Component, Mixins } from 'vue-property-decorator';
import Page from '@/views/Page.vue';
import { PageState } from '@/models/PageState';
import { VuetifyMixin, PaginatedTableMixin, StringsMixin, BAIconsMixin, CoachRoutingMixin } from '@/mixins';
import { ScoutingReportModel, ScoutingReportModelEx } from '@/models/scoutingReport/ScoutingReportModel';
import { adminStore, notificationStore } from '@/store';
import { DataTableHeader } from 'vuetify';
import { AthleteProfileModel, CoachProfileModel } from '@/models';
import RecruitingProfileInfo from '@/components/profile/recruiting/RecruitingProfileInfo.vue';
import ScoutingReportBrief from '@/components/profile/athlete/ScoutingReportBrief.vue';

@Component({
	components: {
		Page,
		RecruitingProfileInfo,
		ScoutingReportBrief
	}
})
export default class ScoutingReportsAdminDashboard extends Mixins(VuetifyMixin, StringsMixin, PaginatedTableMixin, BAIconsMixin, CoachRoutingMixin){
	pageState: PageState = new PageState('Initial');
	search: string = '';

	mounted(): void{
		this.tableOptions.sortBy = ['submitted','createdAt'];
		this.tableOptions.sortDesc = [true, true];
		this.localForagePersistFields = [['search', ''],['tableOptions.page', 1],['tableOptions.itemsPerPage', 25]];
	}

	athletes: Array<AthleteProfileModel> = [];
	coaches: Array<CoachProfileModel> = [];
	isScoutingReportsLoading: boolean = false;
	isScoutingReportsLoaded: boolean = false;
	async loadDetails(): Promise<Array<ScoutingReportModelEx>> {
		return Promise.all( adminStore.scoutingReports.map(report => ScoutingReportModelEx.fromScoutingReport(report, this.athletes, this.coaches)) );
	}
	async loadTable(): Promise<void> {
		this.isScoutingReportsLoaded = false;
		this.isScoutingReportsLoading = true;
		try {
			var query = this.TableQuery<ScoutingReportModel>(this.search, ['athleteId', 'notes', 'playerDetails.lastName', 'playerDetails.firstName', 'playerDetails.email', 'scoutingReport.gameDetails.teamName']);
			await adminStore.loadScoutingReports({ query, options: this.TableQueryOptions });
			this.scoutingReportsDetails = await this.loadDetails();
		} catch(e) {
			notificationStore.pushSnackbarError({message: `Error loading scouting reports: ${e}`});
		} finally {
			this.pageState = new PageState('Ready');
		}
		this.isScoutingReportsLoaded = true;
		this.isScoutingReportsLoading = false;
	}

	get PageLoading(): boolean {
		return adminStore.scoutingReportsLoading || this.isScoutingReportsLoading;
	}
    get TotalItems(): number {
        return adminStore.scoutingReportsCount;
    }

	get TableHeaders(): DataTableHeader<any>[] {
		return [
			{ text: 'Date', value: 'createdAt', sortable: false },
			{ text: 'ID', value: 'id', sortable: false },
			{ text: 'Player', value: 'name', sortable: false },
			{ text: 'Coach', value: 'coach', sortable: false },
			{ text: 'Status', value: 'submitted', sortable: false },
			{ text: 'Score', value: 'performance', sortable: false, width:'20', align:'center' },
			{ text: 'AthleteId', value: 'athleteId', sortable: false },
			{ text: '', value: 'actions', sortable: false },
			{ text: '', value: 'data-table-expand', sortable: false },
		]
	}

	scoutingReportsDetails: Array<ScoutingReportModelEx> = [];
	get ScoutingReports(): Array<ScoutingReportModelEx> {
		if( !adminStore.scoutingReportsInitialized) return [];
		return this.scoutingReportsDetails;
	}
	get ScoutingReportsCount(): number {
		return adminStore.scoutingReportsCount;
	}

	editingReport: boolean = false;
	editReport: ScoutingReportModelEx | null = null;
	editLinkAthlete: AthleteProfileModel | null = null;
	onLinkScoutingReport(report: ScoutingReportModelEx): void {
		this.editReport = report;
		this.editingReport = true;
	}
	onUpdateEditAthlete(athlete: AthleteProfileModel): void {
		this.editLinkAthlete = athlete?? null;
	}
	onCancelEdit(): void {
		this.editReport = null;
		this.editingReport = false;
	}
	onRemoveLink(): void {
		try {
			this.editReport.athleteId = null;
			this.editReport.lastModified = new Date();
			this.updateScoutingReport(this.editReport);
		} catch(e) {
			notificationStore.pushSnackbarError(e);
		}
	}
	onConfirmEdit(): void {
		if( !this.editLinkAthlete ) return;

		try {
			this.editReport.athleteId = this.editLinkAthlete.id;
			this.editReport.lastModified = new Date();
			this.editReport.playerDetails.firstName = this.editLinkAthlete.firstName;
			this.editReport.playerDetails.lastName = this.editLinkAthlete.lastName;
			if( this.editLinkAthlete.birthDate ) {
				this.editReport.playerDetails.dateOfBirth = this.editLinkAthlete.birthDate;
				this.editReport.playerDetails.yearOfBirth = this.editLinkAthlete.birthDate.getFullYear();
			}
			this.updateScoutingReport(this.editReport);
		} catch(e) {
			notificationStore.pushSnackbarError(e);
		}
	}
	async updateScoutingReport(scoutingReport: ScoutingReportModelEx) {
		try {
			const updatedReport = await adminStore.patchScoutingReport(scoutingReport);
			if( this.editReport.athleteId ) {
				notificationStore.pushSnackbarSuccess({message: "Report " + scoutingReport.id + " linked to " + this.editReport.PlayerName})
			} else {
				notificationStore.pushSnackbarWarning({message: "Report " + scoutingReport.id + " UNLINKED FROM " + this.editReport.PlayerName})
			}
			this.editReport = null;
			this.editingReport = false;
			this.editLinkAthlete = null;
			this.updateSearch();
		} catch(e) {
			notificationStore.pushSnackbarError(e);
		}
	}
}
